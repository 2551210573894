.navbar-vertical {
    width: 100%; /* Defina a largura desejada para a barra vertical */
    background-color: #f8f9fa; /* Altere a cor de fundo conforme necessário */
}
/* Personalize o estilo dos itens da navbar conforme necessário */
.navbar-vertical .nav-link {
    padding: .5rem 1rem; /* Adicione espaçamento interno conforme necessário */
    color: #495057; /* Altere a cor do texto conforme necessário */
}

.container_selection_moedas{
    max-height: 50rem;
    height: 50rem;
    overflow-y: auto;
}

.container_estrategias{
    height: 230px;
    overflow-y: auto;
    max-height: 230px;
    overflow-x: hidden;
}

.cursor_pointer{
    cursor: pointer;
}

.display_modal{
    display: block !important;
}

.bg_navmenu{
    background-color: #020c18;
}

.font_color_navmenu{
    color: #209e20 !important;
    font-family: Calibri;
    font-weight: bold;
}


.font_color_bg_navmenu{
    color: #020c18 !important;
    font-family: Calibri;
}

.bg_color_font_navmenu{
    background-color: #209e20 !important;
}

::-webkit-scrollbar {
    width: 10px; /* largura da barra de rolagem */
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent; /* cor de fundo da barra de rolagem */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #1f9e20; /* cor do manipulador da barra de rolagem */
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; /* cor do manipulador da barra de rolagem quando hover */
}

.overflow_auto{
    overflow-y: auto;
}

.card_login{
    width: 40%;
    height: 60vh;
}

.margin_top_100{
    margin-top: 100px;
}

.login-page {
    width: 100%;
    height: 100vh;
    display: inline-block;
    /*display: flex;*/
    align-items: center;
}

.z-index-1000{
    z-index: 1000;
}

.font_calibri{
    font-family: Calibri;
}

.bg_login{
    background-color: #041830;
}

.round_left{
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.round_rigth{
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.radius_10{
    border-radius: 10px;
}

.content_gerenciamento_sis{
    height: 85%;
    width: 95%;
    background-color: rgba(0, 0, 0, 0.4);
}

.content_contents_return_ger{
    height: 90%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}