:root{
    --color_green_pattern_home: #1f9e20;
}

.font_color_green_home{
    color: var(--color_green_pattern_home) !important;
}

.nav-link.active{
    color: var(--color_green_pattern_home) !important;
}

.bg_green_pattern_home{
    background-color: var(--color_green_pattern_home) !important;
}

.border_bot_navmenu{
    border-bottom: 2px solid var(--color_green_pattern_home);
}

.height_content{
    height: 60rem;
}

.font_size_headers_in_content_home{
    font-size: 35px;
}

.font_size_mini_headers_in_content_home{
    font-size: 30px;
}

.font_size_footer{
    font-size: 16px;
}

.font_size_lorem{
    font-size: 19px;
}

.font_size_about_plan{
    font-size: 22px;
}

.overflow-x-none{
    overflow-x: hidden;
}

.boder_homezinho_2{
    border: 5px solid var(--color_green_pattern_home);
    border-radius: 15px;
}

.direction_right{
    direction: rtl;
}


.radius_15{
    border-radius: 15px;
}

.cardzinhos_screen_dash:hover{
    opacity: 0.6;
    cursor: pointer;
}

.btn_assinar{
    background-color: #eacd3b !important;
}

.dropdown-item:hover{
    color: black !important;
}

.dropdown-item.active, .dropdown-item:active{
    background-color: var(--color_green_pattern_home) !important;
}

.chakra-petch-regular {
  font-family: "Chakra Petch", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}


.btn_pattern{
    border: 4px solid var(--color_green_pattern_home) !important;
    color: var(--color_green_pattern_home) !important;
}

.btn_contato{
    background-color: var(--color_green_pattern_home) !important;
    font-family: "Chakra Petch", sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 25px !important;
}

#navBarHome .nav-link:hover{
    color: var(--color_green_pattern_home) !important
}

.z-index-2000{
    z-index: 2000;
}

.container_aulas_ce{
    height: 40vw;
    width: 80%;
}

.border_hidden{
    border: none !important;
}


.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro semi-transparente */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Certifique-se de que o spinner esteja no topo */
}

.spinner {
  border: 16px solid #f3f3f3; /* Cor do anel */
  border-top: 16px solid var(--color_green_pattern_home); /* Cor do anel giratório */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite; /* Animação do giro */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}